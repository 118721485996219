<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">防疫诊疗工作统计</div> 
    </div>

    <div class="content_box">
      <!-- 防疫列表 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="fw_bold main_color ml_20 h_50 lh_50"><span>兽医列表</span></div>

          <el-carousel 
            class="swiper color_fff" 
            indicator-position="none" 
            direction="vertical" 
            :autoplay="true"
            :interval="5000"
            @change="changeTopChart"
          >
            <el-carousel-item 
              class="shou_yi_item flex_row_around" 
              v-for="(item, index) in userList" :key="index"
            >
              <div class="avatar_box w_30_p h_78_p" @click="showFangYiInfo(item.name)">
                <img :src="item.avatar" alt="">
              </div>
              
              <div class="user_info_box w_60_p">
                <div class="item">
                  <span class="main_color">姓名：</span> {{ item.name || '' }}
                </div>
                <!-- <div class="item mb_2">
                  年龄：{{ item.age || '37' }}
                </div> -->
                <div class="item">
                  <span class="main_color"> 嘎查村：</span> {{ item.hamlet || '' }}
                </div>
                <div class="item">
                  <span class="main_color">电话：</span> {{ item.phone|| '' }}
                </div>
                <div class="item">
                  <span class="main_color">工龄：</span> {{ item.workAge || '' }}
                </div>
                <div class="item">
                  <span class="main_color"> 包点包地：</span> {{ item.baoDian || '' }}
                </div>
                <div class="item flex_row_between">
                  <span class="main_color">防疫质量：</span> 
                    <div class="star w_15 h_15" v-for="(star, i) in item.star" :key="i">
                      <img class="full" :src="starIcon" alt="">
                    </div>
                </div>
                <div class="item">
                  <!-- <span class="main_color">距离：</span>10 km -->
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>  
        </div> 

        <!-- <div class="left_box">
          <div class=""><span>疫苗列表</span></div>

          <div class="list_box">
            <div class="list_item">
              <span class="w_25_p main_color">编号</span> 
              <div class="w_25_p main_color">名称</div> 
              <div class="w_25_p main_color">存量</div> 
              <div class="w_25_p main_color">用量</div> 
            </div>
            
            <div  class="list_item" v-for="(item, index) in yiMiaoList" :key="index" >
              <div class="w_25_p">{{ item.num }}</div> 
              <div class="w_25_p" @click="clickYiMiaoMingCheng(index)">{{ item.name }}</div> 
              <div class="w_25_p">{{ item.count }}</div> 
              <div class="w_25_p">{{ item.use_count }}</div> 
            </div>
          </div>
        </div>  -->
      </div>

      <!-- 疫苗用量统计 -->
      <div class="content_item_box ph_10">
        <div class="bar_box w_full h_49_p">
          <div class="fw_bold main_color text_center h_50 lh_50"><span>疫苗用量统计</span></div>
          
          <fang-yi-zhu-zhuang-tu class="chart relative top_-30" :value="data1" />
        </div> 
      </div>

      <!-- 防疫列表 -->
      <div class="content_item_box">
        <div class="right_box">
          <div class="text_right main_color fw_bold mr_20 h_50 lh_50"><span>防疫列表</span></div>

          <div class="list_box h_90_p ph_5 overflowY_auto" v-if="showList">   
            <el-carousel 
              class="swiper2 color_fff" 
              indicator-position="none" 
              direction="vertical" 
              :autoplay="true"
              :interval="5000"
            >
              <el-carousel-item 
                class="list_item border_radius_5 ph_5" 
                v-for="(item, index) in fangYiList" :key="index"
              >   
                <div class="flex_row_around border_b_1_s_ccc pb_5">
                  <div class="w_50_p">
                    <span class="main_color">牧户：</span> {{ item.muHuName || '' }}
                  </div>
                  <div class="w_50_p">
                    <span class="main_color">防疫日期：</span> {{ item.date || '' }}
                  </div>
                </div> 

                <div class="flex_row_between mt_5">
                  <div class="w_20_p">
                    <span class="main_color">牲畜:</span> {{ item.fangYi1.animalType || '' }}
                  </div>
                  <div class="w_25_p">
                    <span class="main_color">存栏：</span> {{ item.fangYi1.cunLanCount || '' }}
                  </div>
                  <div class="w_30_p">
                    <span class="main_color">疫苗:</span> {{ item.fangYi1.mianYiType || '' }}
                  </div>
                </div> 

                <div class="flex_row_between">
                  <div class="w_75_p">
                    <span class="main_color">疫苗名称：</span> {{ item.fangYi1.yiMiaoName || '' }}
                  </div>
                  
                  <div class="w_25_p">
                    <span class="main_color">免疫：</span> {{ item.fangYi1.mianYiCount || '' }}
                  </div>
                </div>

                <div class="flex_row_between border_b_1_s_ccc pb_5">
                  <div class="w_30_p">
                    <span class="main_color">厂家:</span> {{ item.fangYi1.changJia || '' }}
                  </div>
                  <div class="w_40_p">
                    <span class="main_color">批号：</span> {{ item.fangYi1.piHao || '' }}
                  </div>
                  <div class="w_30_p">
                    <span class="main_color">用量:</span> {{ item.fangYi1.yongLiang || '' + 'ml' }}
                  </div>
                </div> 

                <div class="flex_row_between mt_5">
                  <div class="w_20_p">
                    <span class="main_color">牲畜:</span> {{ item.fangYi2.animalType || '' }}
                  </div>
                  <div class="w_25_p">
                    <span class="main_color">存栏：</span> {{ item.fangYi2.cunLanCount || '' }}
                  </div>
                  <div class="w_30_p">
                    <span class="main_color">疫苗:</span> {{ item.fangYi2.mianYiType || '' }}
                  </div>
                </div> 

                <div class="flex_row_between">
                  <div class="w_75_p">
                    <span class="main_color">疫苗名称：</span> {{ item.fangYi2.yiMiaoName || '' }}
                  </div>
                  <div class="w_25_p">
                    <span class="main_color">免疫：</span> {{ item.fangYi2.mianYiCount || '' }}
                  </div>
                </div>

                <div class="flex_row_between">
                  <div class="w_30_p">
                    <span class="main_color">厂家:</span> {{ item.fangYi2.changJia || '' }}
                  </div>
                  <div class="w_40_p">
                    <span class="main_color">批号：</span> {{ item.fangYi2.piHao || '' }}
                  </div>
                  <div class="w_30_p">
                    <span class="main_color">用量:</span> {{ item.fangYi2.yongLiang || '' + 'ml' }}
                  </div>
                </div> 
              </el-carousel-item>
            </el-carousel>        
            <!-- <div  class="list_item main_color  border_radius_5 ph_5" v-for="(item, index) in fangYiList1" :key="index" > -->
                
              <!-- </div> -->
            <!-- </div> -->

          </div>
        </div> 
        
        <div class="bottom_div mt_20">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FangYiZhuZhuangTu from '../charts/fang-yi-zhu-zhuang-tu.vue'
  import BingTu from '../charts/bing-tu.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'

  export default {
    name: 'GongZuoTongJi',
    components: { 
      BingTu, 
      kongXinBingTu,
      FangYiZhuZhuangTu
    },
    data() {
      return {
        starIcon: require('../../../../assets/images/ba-lin-you-qi/star.svg'),
        
        data1: {},
        showList: false,

        currentShouYiIndex: 0,

        userList: [
          {
            avatar: require('../../../../assets/images/shou-yi/img1.jpg'),
            name: '巴林朝拉',
            age: '34',
            workAge: '12',
            addr: '巴林右旗索博日嘎镇索博日嘎嘎查',
            phone: '137****5599',
            star: 5,
            istance: 9,
            hamlet: '毛敦敦达',
            baoDian: '树中嘎查'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img2.jpg'),
            name: '巴特尔',
            age: '36',
            workAge: '4',
            addr: '巴林右旗大板镇镇红旗嘎查',
            phone: '156****9867',
            star: 4,
            istance: 4,
            hamlet: '塔本花',
            baoDian: '乌苏一肯、五一、塔布格'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img3.jpg'),
            name: '代钦',
            age: '32',
            workAge: '6',
            addr: '巴林右旗大板镇镇红旗嘎查',
            phone: '137****2889',
            star: 5,
            istance: 9,
            hamlet: '查干锡热',
            baoDian: '查干锡热嘎查、查干敖包组'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img4.jpg'),
            name: '贺喜格都愣',
            age: '37',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '176****6274',
            star: 5,
            istance: 9,
            hamlet: '珠拉沁',
            baoDian: '珠拉沁嘎查'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img5.jpg'),
            name: '吉拉嘎木吉',
            age: '32',
            workAge: '3',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '176****2673',
            star: 5,
            istance: 6,
            hamlet: '沙巴尔台',
            baoDian: '红星、沙布台、布都花'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img6.jpg'),
            name: '那木尔',
            age: '28',
            workAge: '3',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '175****9844',
            star: 5,
            istance: 4,
            hamlet: '查干沐沦',
            baoDian: '宝楞嘎查、三希布图'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img7.jpg'),
            name: '那木日',
            age: '32',
            workAge: '5',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '175****2344',
            star: 5,
            istance: 4,
            hamlet: '呼勒斯图布朗',
            baoDian: '查干沐沦嘎查'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img8.jpg'),
            name: '祁永安',
            age: '43',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '175****2784',
            star: 5,
            distance: 8,
            hamlet: '达马金村',
            baoDian: '达马金村'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img9.jpg'),
            name: '苏日力格',
            age: '37',
            workAge: '6',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '137****9884',
            star: 5,
            istance: 12,
            hamlet: '胡特勒',
            baoDian: '塔本花、西热、胡特乐'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img10.jpg'),
            name: '唐金福',
            age: '39',
            workAge: '6',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '巴彦锡那村',
            baoDian: '巴彦锡那村'
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img11.jpg'),
            name: '乌力吉白音',
            age: '43',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img12.jpg'),
            name: '新巴雅尔',
            age: '45',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img13.jpg'),
            name: '额尔敦套格套',
            age: '45',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img14.jpg'),
            name: '乌力吉门德',
            age: '45',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img15.jpg'),
            name: '宝山',
            age: '45',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img16.jpg'),
            name: '扎拉嘎巴雅尔',
            age: '45',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
          {
            avatar: require('../../../../assets/images/shou-yi/img17.jpg'),
            name: '毛乐儿额尔德尼',
            age: '45',
            workAge: '8',
            addr: '巴林右旗大板镇镇大冷嘎查',
            phone: '133****9878',
            star: 5,
            istance: 16,
            hamlet: '',
            baoDian: ''
          },
        ],
        
        yongYiList: [
          [2836,21623,1240,16,16],
          [2628,26110,6120,16,16],
          [1825,7624,3150,15,15],
          ['',9836,4130,6,6],
          [3580,12160,6350,12,12],
          [2630,27895,8910,20,20],
          [1986,6235,2130,8,8],
          [0,4926,1360,85,85],
          [480,3650,938,0,0],
          [0,8637,3215,45,45],
          ['', '', '', '', ''],
          ['', '', '', '', ''],
          ['', '', '', '', ''],
          ['', '', '', '', ''],
          ['', '', '', '', ''],
          ['', '', '', '', ''],
          ['', '', '', '', '']
        ],

        fangYiList1: [
          {
            muHuName: '萨如拉',
            date: '2023.4.10',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '16',
              mianYiType: '二价苗',
              mianYiCount: '16',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '40',
              mianYiType: '二价苗',
              mianYiCount: '40',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '那仁巴图',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '25',
              mianYiType: '二价苗',
              mianYiCount: '25',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '265',
              mianYiType: '二价苗',
              mianYiCount: '265',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '额尔灯朝格图',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '13',
              mianYiType: '二价苗',
              mianYiCount: '13',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '100',
              mianYiType: '二价苗',
              mianYiCount: '100',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '那顺巴亚尔',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '6',
              mianYiType: '二价苗',
              mianYiCount: '6',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '80',
              mianYiType: '二价苗',
              mianYiCount: '80',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '格日乐图',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '35',
              mianYiType: '二价苗',
              mianYiCount: '35',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '150',
              mianYiType: '二价苗',
              mianYiCount: '150',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '阿拉坦德力根',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '42',
              mianYiType: '二价苗',
              mianYiCount: '42',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '151',
              mianYiType: '二价苗',
              mianYiCount: '151',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '图门吉日嘎拉',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '30',
              mianYiType: '二价苗',
              mianYiCount: '30',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '230',
              mianYiType: '二价苗',
              mianYiCount: '230',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '朝鲁巴特尔',
            date: '2023.4.11',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '30',
              mianYiType: '二价苗',
              mianYiCount: '30',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '25',
              mianYiType: '二价苗',
              mianYiCount: '25',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '那仁满都呼',
            date: '2023.4.10',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '68',
              mianYiType: '二价苗',
              mianYiCount: '68',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '260',
              mianYiType: '二价苗',
              mianYiCount: '260',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
        ],

        fangYiList2: [
          {
            muHuName: '额日德木图',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '33',
              mianYiType: '二价苗',
              mianYiCount: '33',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '310',
              mianYiType: '二价苗',
              mianYiCount: '310',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '金花',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '10',
              mianYiType: '二价苗',
              mianYiCount: '10',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '60',
              mianYiType: '二价苗',
              mianYiCount: '60',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '斯琴毕力格',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '2',
              mianYiType: '二价苗',
              mianYiCount: '2',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '35',
              mianYiType: '二价苗',
              mianYiCount: '35',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '斯琴巴亚尔',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '5',
              mianYiType: '二价苗',
              mianYiCount: '5',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '230',
              mianYiType: '二价苗',
              mianYiCount: '230',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '额尔敦通拉嘎',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '40',
              mianYiType: '二价苗',
              mianYiCount: '40',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '150',
              mianYiType: '二价苗',
              mianYiCount: '150',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '青老塔',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '27',
              mianYiType: '二价苗',
              mianYiCount: '27',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '35',
              mianYiType: '二价苗',
              mianYiCount: '35',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '巴特尔',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '40',
              mianYiType: '二价苗',
              mianYiCount: '40',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '20',
              mianYiType: '二价苗',
              mianYiCount: '20',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '哈斯道尔吉',
            date: '2023.4.6',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '40',
              mianYiType: '二价苗',
              mianYiCount: '40',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '190',
              mianYiType: '二价苗',
              mianYiCount: '190',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
          {
            muHuName: '那仁毕力格',
            date: '2023.4.10',
            fangYi1: {
              animalType: '牛',
              cunLanCount: '80',
              mianYiType: '二价苗',
              mianYiCount: '80',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '1',
            },
            fangYi2: {
              animalType: '羊',
              cunLanCount: '150',
              mianYiType: '二价苗',
              mianYiCount: '150',
              yiMiaoName: 'O型A型二价灭活疫苗',
              changJia: '内蒙',
              piHao: '22370802',
              yongLiang: '0.5',
            }
          },
        ],







        userInfo: {
          name: "乌力吉",
          num: "4148133",
          village: '大板镇',
          hamlet: "大板镇浩饶沁嘎查",
          phone: '185****2345',
          yu_zhong_num: 32,
          ear_num: 1012,
          date1: '2022.8-10',
          date2: '2023-3-21',
          record: [
            {
              name: "宝音",
              num: "41415175",
              type: '口蹄疫',
              village: '大板镇',
              hamlet: "大板镇红旗嘎查",
              phone: '139****5608',
              yu_zhong_num: 43,
              ear_num: 1005,
              pin_pai: '品牌A',
              date1: '2022.2-13',
              date2: '2023-11-15'
            },
            {
              name: "巴特尔",
              num: "41415132",
              type: '布病',
              hamlet: "大板镇苏艾力嘎查",
              phone: '158****6998',
              yu_zhong_num: 89,
              ear_num: 1006,
              pin_pai: '品牌B',
              date1: '2022.3-13',
              date2: '2023-12-15'
            },
            {
              name: "阿音",
              num: "41415132",
              type: '高致病性禽流感',
              hamlet: "大板镇苏艾力嘎查",
              phone: '158****6998',
              yu_zhong_num: 89,
              ear_num: 1006,
              pin_pai: '品牌B',
              date1: '2022.3-13',
              date2: '2023-12-15'
            }
          ]
        },

        list1: [
          {
            name: "乌力吉",
            num: "4148133",
            type: '牛结核病',
            hamlet: "大板镇浩饶沁嘎查",
            phone: '185****2345',
            yu_zhong_num: 32,
            ear_num: 1012,
            date1: '2022.8-10',
            date2: '2023-3-21',
            record: [
              {
                name: "宝音",
                num: "41415175",
                village: '大板镇',
                type: '口蹄疫',
                hamlet: "大板镇红旗嘎查",
                phone: '139****5608',
                yu_zhong_num: 43,
                ear_num: 1005,
                pin_pai: '品牌A',
                date1: '2022.2-13',
                date2: '2023-11-15'
              },
              {
                name: "巴特尔",
                num: "41415132",
                village: '幸福之路苏木',
                type: '布病',
                hamlet: "幸福之路苏木床金嘎查",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "阿音",
                num: "41415132",
                village: '索博日嘎镇',
                type: '牛结核病',
                hamlet: "索博日嘎镇常兴村",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              }
            ]
          },
          {
            name: "巴特尔",
            num: "1833",
            hamlet: "大板镇大冷村",
            phone: '157****6545',
            yu_zhong_num: 49,
            ear_num: 1043,
            date1: '2022.5-02',
            date2: '2023-2-05',
            record: [
              {
                name: "满达",
                num: "41432453",
                village: '大板镇',
                hamlet: "大板镇苏红旗嘎查",
                phone: '178****6498',
                yu_zhong_num: 39,
                ear_num: 1007,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "宝音斯琴",
                phone_number: "15849679411",
                num: "17387928",
                village: '大板镇',
                hamlet: "大板镇苏大冷嘎查",
                phone: '176****3248',
                yu_zhong_num: 53,
                ear_num: 1008,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "德宝",
                phone_number: "13789532215",
                num: "2387928",
                village: '大板镇',
                hamlet: "大板镇苏西哈嘎查",
                phone: '175****8748',
                yu_zhong_num: 34,
                ear_num: 1009,
                pin_pai: '品牌B',
                date1: '2022.6-23',
                date2: '2023-4-25'
              },
            ]
          },
          {
            name: "牧人",
            num: "41415118",
            hamlet: "大板镇西哈嘎查",
            phone: '159****6798',
            yu_zhong_num: 67,
            ear_num: '0042',
            date1: '2022.5-23',
            date2: '2023-2-28',
            record: [
              {
                name: "宝音斯琴",
                phone_number: "15849679411",
                num: "17387928",
                village: '大板镇',
                hamlet: "大板镇苏大冷嘎查",
                phone: '176****3248',
                yu_zhong_num: 53,
                ear_num: 1008,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "德宝",
                phone_number: "13789532215",
                num: "2387928",
                village: '大板镇',
                hamlet: "大板镇苏西哈嘎查",
                phone: '175****8748',
                yu_zhong_num: 34,
                ear_num: 1009,
                pin_pai: '品牌B',
                date1: '2022.6-23',
                date2: '2023-4-25'
              },
              {
                name: "斯日敖都",
                phone_number: "15344153083",
                num: "238948",
                village: '大板镇',
                hamlet: "大板镇苏胡斯台嘎查",
                phone: '159****2342',
                yu_zhong_num: 54,
                ear_num: 1010,
                pin_pai: '品牌B',
                date1: '2022.4-13',
                date2: '2023-1-15'
              },
            ]
          },
          {
            name: "宝音",
            num: "41415175",
            hamlet: "大板镇红旗嘎查",
            phone: '139****5608',
            yu_zhong_num: 43,
            ear_num: 1005,
            pin_pai: '品牌A',
            date1: '2022.2-13',
            date2: '2023-11-15',
            record: [
              {
                name: "巴特尔",
                num: "41415132",
                village: '大板镇',
                hamlet: "大板镇苏艾力嘎查",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "阿音",
                num: "41415132",
                village: '大板镇',
                hamlet: "大板镇苏艾力嘎查",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "满达",
                num: "41432453",
                village: '大板镇',
                hamlet: "大板镇苏红旗嘎查",
                phone: '178****6498',
                yu_zhong_num: 39,
                ear_num: 1007,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "宝音斯琴",
                phone_number: "15849679411",
                num: "17387928",
                village: '大板镇',
                hamlet: "大板镇苏大冷嘎查",
                phone: '176****3248',
                yu_zhong_num: 53,
                ear_num: 1008,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "德宝",
                phone_number: "13789532215",
                num: "2387928",
                village: '大板镇',
                hamlet: "大板镇苏西哈嘎查",
                phone: '175****8748',
                yu_zhong_num: 34,
                ear_num: 1009,
                pin_pai: '品牌B',
                date1: '2022.6-23',
                date2: '2023-4-25'
              },
            ]
          },
          {
            name: "巴特尔",
            num: "41415132",
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "阿音",
            num: "41415132",
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "满达",
            num: "41432453",
            hamlet: "大板镇苏红旗嘎查",
            phone: '178****6498',
            yu_zhong_num: 39,
            ear_num: 1007,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "宝音斯琴",
            phone_number: "15849679411",
            num: "17387928",
            hamlet: "大板镇苏大冷嘎查",
            phone: '176****3248',
            yu_zhong_num: 53,
            ear_num: 1008,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "德宝",
            phone_number: "13789532215",
            num: "2387928",
            hamlet: "大板镇苏西哈嘎查",
            phone: '175****8748',
            yu_zhong_num: 34,
            ear_num: 1009,
            pin_pai: '品牌B',
            date1: '2022.6-23',
            date2: '2023-4-25'
          },
          {
            name: "斯日敖都",
            phone_number: "15344153083",
            num: "238948",
            hamlet: "大板镇苏胡斯台嘎查",
            phone: '159****2342',
            yu_zhong_num: 54,
            ear_num: 1010,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
          {
            name: "乌云参丹",
            phone_number: "13948361412",
            num: "237832",
            hamlet: "大板镇苏胡斯台嘎查",
            phone: '176****5642',
            yu_zhong_num: 56,
            ear_num: 1011,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
          {
            name: "那顺白音",
            phone_number: "15004869151",
            num: "894373",
            hamlet: "大板镇苏红旗嘎查",
            phone: '159****4342',
            yu_zhong_num: 14,
            ear_num: 1011,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
          {
            name: "布仁巴亚尔",
            phone_number: "15047600256",
            num: "248892",
            hamlet: "大板镇新立村",
            phone: '156****2772',
            yu_zhong_num: 54,
            ear_num: 1012,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
        ],

        list2: [
          {
            name: "额尔灯毕力格",
            num: "4148133",
            village: '大板镇',
            hamlet: "大板镇浩饶沁嘎查",
            phone: '185****2345',
            yu_zhong_num: 32,
            ear_num: 1012,
            date1: '2022.8-10',
            date2: '2023-3-21'
          },
          {
            name: "吉仁巴雅尔",
            num: "1833",
            village: '大板镇',
            hamlet: "大板镇大冷村",
            phone: '157****6545',
            yu_zhong_num: 49,
            ear_num: 1043,
            date1: '2022.5-02',
            date2: '2023-2-05'
          },
          {
            name: "朱广福",
            num: "41415118",
            village: '大板镇',
            hamlet: "大板镇西哈嘎查",
            phone: '159****6798',
            yu_zhong_num: 67,
            ear_num: '0042',
            date1: '2022.5-23',
            date2: '2023-2-28'
          },
          {
            name: "宝音",
            num: "41415175",
            village: '大板镇',
            hamlet: "大板镇红旗嘎查",
            phone: '139****5608',
            yu_zhong_num: 43,
            ear_num: 1005,
            pin_pai: '品牌A',
            date1: '2022.2-13',
            date2: '2023-11-15'
          },
          {
            name: "巴特尔",
            num: "41415132",
            village: '大板镇',
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "牟宝全",
            num: "41415132",
            village: '大板镇',
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "哈斯达来",
            num: "41432453",
            village: '大板镇',
            hamlet: "大板镇苏红旗嘎查",
            phone: '178****6498',
            yu_zhong_num: 39,
            ear_num: 1007,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
        ],

        yiMiaoList: [
          {
            num: '189843',
            name: '口蹄疫',
            count: 84359,
            use_count: 37565
          },
          {
            num: '326432',
            name: '疯牛疫',
            count: 58439,
            use_count: 44354
          },
          {
            num: '134355',
            name: '禽流感',
            count: 93483,
            use_count: 64354
          },
          {
            num: '54843',
            name: '布病',
            count: 67843,
            use_count: 52324
          },
          {
            num: '82983',
            name: '牛结核病',
            count: 72435,
            use_count: 63829
          },
        ]
      }
    },

    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)

      this.showFangYiList(0)
    },
    
    methods: {
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      initChart() {
        this.data1 = {
          yName: '头',
          y: [2836,21623,1240,16,16]
        }
      },

      // 监听大地图点击
      showVillage(val) {
        this.villageName = val

        this.hideLargeMap = true
      },

      // 点击防疫员头像 显示 防疫 list
      // showFangYiInfo(name) {
      //   if (name == '巴林朝拉') {
      //     this.showList = true
      //     this.fangYiList = this.fangYiList1
      //   } else if (name == '代钦') {
      //     this.showList = true
      //     this.fangYiList = this.fangYiList2
      //   } else {
      //     this.showList = false
      //     this.fangYiList = []
      //   }
      // },

      //  显示 防疫 list
      showFangYiList(val) {
        if (val == 0) {
          this.showList = true
          this.fangYiList = this.fangYiList1
        } else if (val == 2) {
          this.showList = true
          this.fangYiList = this.fangYiList2
        } else {
          this.showList = false
          this.fangYiList = []
        }
      },

      // 更新 top box
      changeTopChart(e) {
        this.currentShouYiIndex = e
        
        this.showFangYiList(e)

        this.data1 = {
          yName: '头',
          y: this.yongYiList[e]
        }
      },

      // 点击防疫员名称
      clickYuZhongYuanName(index) {
        this.activeIndex = index
        this.userInfo = this.list1[index]
      },

      // 改变地图高亮
      changeHighLight(val) {
        this.mapValue.name = val
      },

      // 点击 疫苗名称 显示疫苗种植 数据
      clickYiMiaoMingCheng(index) {
        this.showYimiaoCount = true

        if (index == 0) {
          this.yiMiaoCount = {
            value1: 3328,
            value2: 4324,
            value3: 6442,
            value4: 2895,
            value5: 1723,
            value6: 2128,
            value7: 1328,
            value8: 2128,
            value9: 4328,
            value10: 3228,
          }
        } else if (index == 1) {
          this.yiMiaoCount = {
            value1: 1282,
            value2: 2242,
            value3: 8423,
            value4: 1953,
            value5: 3234,
            value6: 4280,
            value7: 5898,
            value8: 2128,
            value9: 3282,
            value10: 5428,
          }
        } else if (index == 2) {
          this.yiMiaoCount = {
            value1: 4262,
            value2: 4332,
            value3: 6533,
            value4: 1233,
            value5: 4354,
            value6: 6680,
            value7: 3298,
            value8: 1328,
            value9: 5482,
            value10: 2428,
          }
        } else if (index == 3) {
          this.yiMiaoCount = {
            value1: 8934,
            value2: 4738,
            value3: 2345,
            value4: 5433,
            value5: 5332,
            value6: 2435,
            value7: 5323,
            value8: 5235,
            value9: 7543,
            value10: 5434,
          }
        } else if (index == 4) {
          this.yiMiaoCount = {
            value1: 3785,
            value2: 4738,
            value3: 9345,
            value4: 4533,
            value5: 7432,
            value6: 5435,
            value7: 4323,
            value8: 3435,
            value9: 4543,
            value10: 2334,
          }
        }

        
      }
    }
  }
</script>

<style scoped lang="less">
  // @import url('../../../../assets/css/bigscreen.less');
  .activeColor {color: rgb(13, 246, 94) !important; }

  .swiper { 
    width: 100%;
    height: 90% !important;
    padding: 5px;
    box-sizing: border-box;
    // border: 1px solid #f00;
  }
  .shou_yi_item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 0;

    .avatar_box {
      img { width: 100%; height: 70%; border-radius: 10px; }
    }

    .user_info_box {
      width: 50%;
      height: 100%;
      position: relative;
      top: -2px;
    }
  }

  .left_box {

    .title { 
      width: 100%;
      height: 10%;
      position: relative;
      
      span {
        font-weight: bold; 
        font-size: 18px;
        color: #fff; 
        cursor: pointer; 
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .list_box {
      width: 100%;
      height: 90%;
      overflow: auto;
      margin-top: 0px;

      .list_item {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        padding: 0 10px;
        display: flex;
        gap: 5;
        color: #fff;

        // span { width: 60px; }
        // .name { width: 30%; cursor: pointer; }   
        // .value1 { width: 25%; }
        // .value2 { width: 25%; }
        // .value3 { width: 20%; }
        // .value4 { width: 20%; }
      }
    }
    .list_box::-webkit-scrollbar {
      width: 0px;
      height: 100%;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
      border-radius: 10px;
      background: #2366fc;
    }
  }

  .bar_box {
    width: 100%;

    .title { 
      width: 100%;
      height: 10%;
      position: relative;
      
      
      span {
        font-weight: bold; 
        font-size: 18px;
        color: #fff; 
        cursor: pointer; 
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .list_box {
      width: 100%;
      height: 90%;
      overflow: auto;
      margin-top: 0px;

      .list_item {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        padding: 0 10px;
        display: flex;
        gap: 5;
        color: #fff;

        // span { width: 60px; }
        // .name { width: 30%; cursor: pointer; }   
        // .value1 { width: 25%; }
        // .value2 { width: 25%; }
        // .value3 { width: 20%; }
        // .value4 { width: 20%; }
      }
    }
    .list_box::-webkit-scrollbar {
      width: 0px;
      height: 100%;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
      border-radius: 10px;
      background: #2366fc;
    }
  }

  .right_box {
    width: 33.3333%;
    height: 100%;

    
    .title { 
      width: 100%;
      height: 10%;
      position: relative;
      border: 1px solid #f00;
      
      span {
        font-weight: bold; 
        color: #fff; 
        cursor: pointer; 
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .list_box::-webkit-scrollbar {
    width: 0px;
    height: 100%;
    background-color: #f5f5f5;
  }

  .list_box::-webkit-scrollbar-thumb {               
    border-radius: 10px;
    background: #2366fc;
  }

  .content_item_box { width: 33.333333% !important; }
  .left_cover_box { 
    .title { color: #fff; text-align: center; padding-top: 4px; font-size: 20px; font-weight: 600; }
  }
  .map_cover_box { height: 100% !important; }
  

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>